import { getAuthenticatedRequestHeaders } from "shared/services/serviceUtils";

export async function signInWithEmailAndPassword(
  email: string,
  password: string
) {
  const options = {
    method: "POST",
    headers: await getAuthenticatedRequestHeaders({
      optionalAuthHeaders: true,
    }),
    body: JSON.stringify({ email, password }),
  };

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_APIV2_URL}/auth/advisors/token`,
    options
  );
  const body = await response.json();
  if (!response.ok) {
    console.error(
      "signInWithEmailAndPassword() error response from backend:",
      body
    );
    throw new Error(body.message);
  }
  return body.token;
}
export async function createToken() {
  const requestOptions = {
    method: "POST",
    headers: await getAuthenticatedRequestHeaders(),
  };
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_APIV2_URL}/auth/advisors/generateToken`,
      requestOptions
    );
    if (!response.ok) {
      const errorData = await response.json();
      return { error: errorData };
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    alert("There was an error getting a token");
  }
}
